var targets = document.querySelectorAll('[data-expand]');

for (var i = 0; i < targets.length; i += 1) {
  targets[i].addEventListener('click', function() {
    if (this.getAttribute('aria-expanded') === 'true') {
      this.setAttribute('aria-expanded', false);
    } else {
      this.setAttribute('aria-expanded', true);
    }
  });
}
