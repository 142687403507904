var slideshow = document.querySelector('.slideshow');

var preload = function(src) {
  var img = document.createElement('img');
  img.onload = function() {
    document.querySelector('.slideshow__full__image--preload').classList.remove('slideshow__full__image--preload');
  };
  img.src = src;
};

var load = function(e) {
  if (window.innerWidth >= 768) {
    e.preventDefault();
  }
  var src = this.getAttribute('href');
  var lastActive = slideshow.querySelector('.slideshow__nav__item--active');
  full.innerHTML = '<img class="slideshow__full__image slideshow__full__image--preload" src="' + src + '">';
  if (lastActive) {
    lastActive.classList.remove('slideshow__nav__item--active');
  }
  this.classList.add('slideshow__nav__item--active');
  preload(src);
};

if (slideshow) {
  var full = slideshow.querySelector('.slideshow__full');
  var navItems = slideshow.querySelectorAll('.slideshow__nav__item');
  for (var i = 0; i < navItems.length; i += 1) {
    navItems[i].addEventListener('click', load);
  }
  var prev = slideshow.querySelector('.slideshow__prev');
  var next = slideshow.querySelector('.slideshow__next');
  prev.addEventListener('click', () => {
    var click;
    var last = slideshow.querySelector('.slideshow__nav__item--active');
    var index = [...navItems].indexOf(last);
    last.classList.remove('slideshow__nav__item--active');
    if (index > 0) {
      click = navItems[index - 1];
    } else {
      click = navItems[navItems.length - 1];
    }
    click.classList.add('slideshow__nav__item--active');
    click.click();
    click.parentNode.scrollLeft = click.offsetLeft + click.offsetWidth / 2 - click.parentNode.offsetWidth / 2;
  });
  next.addEventListener('click', () => {
    var click;
    var last = slideshow.querySelector('.slideshow__nav__item--active');
    var index = [...navItems].indexOf(last);
    last.classList.remove('slideshow__nav__item--active');
    if (index < navItems.length - 1) {
      click = navItems[index + 1];
    } else {
      click = navItems[0];
    }
    click.classList.add('slideshow__nav__item--active');
    click.click();
    click.parentNode.scrollLeft = click.offsetLeft + click.offsetWidth / 2 - click.parentNode.offsetWidth / 2;
  });
}
