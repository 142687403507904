// toggle navigation open
var toggleNav = document.querySelector('.header__toggle--nav');
var nav = document.querySelector('.nav');

if (toggleNav && nav) {
  toggleNav.addEventListener('click', function() {
    nav.classList.add('nav--active');
  });
}

// toggle search open
var toggleSearch = document.querySelector('.header__toggle--search');
var search = document.querySelector('body .search');

if (toggleSearch && search) {
  var searchInput = document.querySelector('.search__input');
  toggleSearch.addEventListener('click', function() {
    search.classList.add('search--active');
    setTimeout(function() {
      searchInput.focus();
    }, 300);
  });
}
