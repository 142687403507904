// close popups
var popupClose = document.querySelectorAll('.popup__close');

var close = function() {
  var popup = this.closest('.popup');
  popup.classList.remove('popup--active');
};

for (var i = 0; i < popupClose.length; i += 1) {
  popupClose[i].addEventListener('click', close);
}

// newsletter popup
var popupNewsletter = document.querySelector('.popup--newsletter');

if (popupNewsletter) {
  setTimeout(function() {
    if (localStorage.getItem('popupNewsletter') !== 'viewed') {
      popupNewsletter.classList.add('popup--active');
      setTimeout(function() {
        popupNewsletter.focus();
      }, 300);
      localStorage.setItem('popupNewsletter', 'viewed');
    }
  }, 30000);
}

// question popup
var popupQuestion = document.querySelector('.popup--question');

if (popupQuestion) {
  var open = document.querySelector('.popup-open-question');
  if (open) {
    open.addEventListener('click', function() {
      popupQuestion.classList.add('popup--active');
      setTimeout(function() {
        popupQuestion.focus();
      }, 300);
    });
  }
}

// contact popup
var popupContact = document.querySelector('.popup--contact');

if (popupContact) {
  var open = document.querySelector('.popup-open-contact');
  if (open) {
    open.addEventListener('click', function(e) {
      e.preventDefault();
      popupContact.classList.add('popup--active');
      setTimeout(function() {
        popupContact.focus();
      }, 300);
    });
  }
}
