var minimodal = require('minimodal');

var targets = document.querySelectorAll('[data-minimodal]');

for (var i = 0; i < targets.length; i += 1) {
  var modal = minimodal(targets[i], {
    previousButtonHTML: '<span class="svgstore svgstore--angle-up"><svg><title>Previous</title><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/lakelifeseattle/dist/img/svgstore.svg#angle-up"></use></svg></span>',
    nextButtonHTML: '<span class="svgstore svgstore--angle-up"><svg><title>Next</title><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/lakelifeseattle/dist/img/svgstore.svg#angle-up"></use></svg></span>',
    closeButtonHTML: '<span class="svgstore svgstore--x"><svg><title>Close</title><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/lakelifeseattle/dist/img/svgstore.svg#x"></use></svg></span>',
    statusTimeout: 300,
    removeTimeout: 300,
    closeTimeout: 300,
  });
  modal.init();
}
