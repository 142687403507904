var new_map = function(el) {
	var markers = el.querySelectorAll('.marker');
	var args = {
		zoom: 16,
		center: new google.maps.LatLng(0, 0),
		mapTypeId: google.maps.MapTypeId.ROADMAP
	};
	var map = new google.maps.Map(el, args);
	map.markers = [];
	for (var i = 0; i < markers.length; i += 1) {
		add_marker(markers[i], map);
	}
	center_map(map);
	return map;
};

var add_marker = function(el, map) {
	var latlng = new google.maps.LatLng(el.getAttribute('data-lat'), el.getAttribute('data-lng'));
	var marker = new google.maps.Marker({
		position: latlng,
		map: map
	});
	map.markers.push(marker);
	if (el.innerHTML)	{
		var infowindow = new google.maps.InfoWindow({
			content: el.innerHTML
		});
		google.maps.event.addListener(marker, 'click', function() {
			infowindow.open(map, marker);
		});
	}
};

var center_map = function(map) {
	var bounds = new google.maps.LatLngBounds();
	for (var i = 0; i < map.markers.length; i += 1) {
		var latlng = new google.maps.LatLng(map.markers[i].position.lat(), map.markers[i].position.lng());
    bounds.extend(latlng);
	}
	if (map.markers.length === 1) {
    map.setCenter(bounds.getCenter());
    map.setZoom(16);
	}	else {
		map.fitBounds(bounds);
	}
};

var maps = document.querySelectorAll('.acf-map');

for (var i = 0; i < maps.length; i += 1) {
	var map = new_map(maps[i]);
}
