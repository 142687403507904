var autoScrolls = document.querySelectorAll('.feed__scroll--auto');
var loadButtons = document.querySelectorAll('.load__button');

var scroll = function() {
  var button = this.nextElementSibling.querySelector('.load__button');
  if (this.scrollLeft === this.scrollWidth - this.offsetWidth) {
    button.click();
  }
};

var load = function() {
  var button = this.classList.contains('load__button') ? this : this.nextElementSibling.querySelector('.load__button');
  var label = button.querySelector('.load__button__label');
  var labelText = label.textContent;
  var request = new XMLHttpRequest();
  var target = button.parentNode.previousElementSibling;
  var pid = button.getAttribute('data-pid');
  var user = button.getAttribute('data-user');
  var load = button.getAttribute('data-load');
  var paged = parseInt(button.getAttribute('data-paged')) + 1;
  var url = ajaxurl + '?action=load_more&pid=' + pid + '&user=' + user + '&load=' + load + '&paged=' + paged;

  button.setAttribute('data-paged', paged);

  button.classList.add('load__button--loading');
  button.setAttribute('disabled', 'disabled');
  label.textContent = 'Loading';

  request.open('GET', url, true);

  request.onload = function() {
    if (this.status >= 200 && this.status < 400) {
      var resp = this.response;
      if (resp.length > 1) {
        target.insertAdjacentHTML('beforeend', resp);
        button.classList.remove('load__button--loading');
        button.removeAttribute('disabled');
        label.textContent = labelText;
      } else {
        button.innerHTML = 'No more items';
      }
    } else {
      button.innerHTML = 'Server error';
    }
  };

  request.onerror = function() {
    button.innerHTML = 'Connection error';
  };

  request.send();
};

for (var i = 0; i < autoScrolls.length; i += 1) {
  autoScrolls[i].addEventListener('scroll', scroll);
}

for (var i = 0; i < loadButtons.length; i += 1) {
  loadButtons[i].addEventListener('click', load);
}
